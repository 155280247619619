import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import  IconTrash  from "../../icons/IconTrash"

const CartSummary = () => {
  return (
    <section id="CartSummary">
      <p className="font-bold text-2xl uppercase px-10 md:px-0">Cart Summary</p>
      <div className=" w-full flex justify-center px-10 md:px-0 md:max-w-96">
        <div className="flex flex-row md:flex-col w-full">
          <div className="w-auto">
            <StaticImage
              src={"../../images/image1.png"}
              alt={"Product Image"}
              objectFit={"cover"}
              placeholder={"none"}
              loading={"eager"}
              className="h-auto transform duration-300 w-32 md:w-72 xl:w-96"
            />
          </div>
          <div className=" w-full px-5 md:px-0">
            <div className="font-bold uppercase text-sm">
              Dangling diamonds halo circle earrings with round cut Torumaline
              center, set in 14K white gold.
            </div>
            <div className="flex flex-row  py-2">
              <div className="flex justify-start w-full font-bold text-xl">
                $35,000
              </div>
              <div className="flex justify-end w-full">
                <button className="text-left col-span-2 lg:col-span-1 xl:col-span-1 text-sm text-gray-400 hover:text-accent">
                  <nobr>
                    {" "}
                    <IconTrash />
                    &ensp; Remove
                  </nobr>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col px-10 md:px-0">
        <form>
          <br />
          <div>
            <div className="border-t py-2">
              Subtotal: &ensp;
              <b>$25,000</b>
              <br />
              Shipping: &ensp;
              <b>$25,000</b>
              <br />
              Sales Tax: &ensp;
              <b>$25,000</b>
              <br />
            </div>

            <div className="border-t py-2">
              TOTAL: &ensp;{" "}
              <p className="text-xl">
                <b>$35,000</b>
              </p>
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}

export default CartSummary
