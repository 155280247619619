import * as React from 'react';
import { IconPayPal, IconAffirm, IconBank, IconCoin, IconCreditCard } from '../icons/IconsCheckout';

const CheckoutSummary = ({ setStep }) => {
  return (
    <section>
      <div className="flex flex-col py-10 lg:flex-row ">
        <div className="px-10">
          <h1 className="pb-4 text-3xl font-bold uppercase text-accent">SHIPPING DETAILS</h1>
          <div>
            Keith Gray
            <br />
            9595 Harding Ave.
            <br />
            Surfside, FL 33154
          </div>
          <button className="my-5 text-gray-500 hover:text-accent" onClick={() => setStep(1)}>
            Change
          </button>
        </div>
        <div className="mx-5 border-t-2" />
        <div className="px-10 lg:border-l-2">
          <h1 className="pb-4 text-3xl font-bold uppercase text-accent">BILLING DETAILS</h1>
          <div>
            Keith Gray
            <br />
            9595 Harding Ave.
            <br />
            Surfside, FL 33154
          </div>
          <button className="my-5 text-gray-500 hover:text-accent" onClick={() => setStep(1)}>
            Change
          </button>
        </div>
      </div>

      <div className="flex w-full flex-col justify-center p-10">
        <h1 className="text-3xl font-bold uppercase text-accent">PAYMENT METHOD</h1>

        <div className="text-xl uppercase">
          <IconCreditCard /> CREDIT CARD <br />
          <IconPayPal /> PayPal <br />
          <IconAffirm />
          <br />
          <IconBank /> wire transfer <br />
          <IconCoin /> bitpay <br />
        </div>

        <button
          className=" my-5 text-left text-gray-500 hover:text-accent"
          onClick={() => setStep(2)}
        >
          Change
        </button>
      </div>

      <div className="px-10 pb-20 lg:pb-0">
        <button
          className="col-span-2 w-auto rounded-md border-2 border-transparent bg-accent px-5 py-2 font-bold text-white hover:border-accent hover:bg-white hover:text-accent lg:col-span-1 xl:col-span-1"
          onClick={e => {
            e.preventDefault();
            setStep(step => step + 1);
          }}
        >
          COMPLETE ORDER
        </button>
      </div>
    </section>
  );
};

export default CheckoutSummary;
