import * as React from 'react';
import { IconAlert } from '../icons/IconsCheckout';

const CheckoutMessage = () => {
  return (
    <div>
      <section className="flex items-center justify-center border-t-4 border-white bg-gray-600">
        <div className="flex max-w-max flex-row items-center py-10 text-center text-white">
          <div>
            <h1 className="text-3xl font-black">ITEM OUT OF STOCK</h1>
            <p>This item is no longer available. Please choose a different item.</p>
            <br />
            <button className="rounded-md border-2 border-white px-5 py-2 shadow-md hover:bg-accent">
              BROWSE INVENTORY
            </button>
          </div>
        </div>
      </section>

      <section className="flex items-center justify-center border-t-4 border-white bg-red-600">
        <div className="flex max-w-max flex-col items-center  py-10  text-center text-white">
          <IconAlert className={'max-w-max transform animate-ping text-3xl'} />
          <div className=" p-5">
            <h1 className="text-3xl font-black">PAYMENT ERROR</h1>
            <p>Please try a different form of payment</p>
          </div>
        </div>
      </section>
    </div>
  );
};
export default CheckoutMessage;
