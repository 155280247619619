import React from 'react';

export const IconPayPal = ({ className }) => (
  <svg role={'img'} aria-label={'PayPal'} className={'icon icon-paypal' + className}>
    <use xlinkHref="#icon-paypal" />
  </svg>
);
export const IconPlus = ({ className }) => (
  <svg role={'img'} aria-label={'Plus'} className={'icon icon-plus ' + className}>
    <use xlinkHref="#icon-plus" />
  </svg>
);

export const IconAlert = ({ className }) => (
  <svg role={'img'} aria-label={'Alert'} className={'icon icon-alert ' + className}>
    <use xlinkHref="#icon-alert" />
  </svg>
);

export const IconCreditCard = ({ className }) => (
  <svg role={'img'} aria-label={'Credit Card'} className={'icon icon-credit-card ' + className}>
    <use xlinkHref="#icon-credit-card" />
  </svg>
);

export const IconCoin = ({ className }) => (
  <svg role={'img'} aria-label={'Coin'} className={'icon icon-coin ' + className}>
    <use xlinkHref="#icon-coin" />
  </svg>
);

export const IconBank = ({ className }) => (
  <svg role={'img'} aria-label={'Bank'} className={'icon icon-bank ' + className}>
    <use xlinkHref="#icon-bank" />
  </svg>
);

export const IconAffirm = ({ className }) => (
  <svg role={'img'} aria-label={'Affirm'} className={'icon icon-affirm_logo ' + className}>
    <use xlinkHref="#icon-affirm_logo" />
  </svg>
);

function IconsCheckout() {
  return (
    <svg
      aria-hidden="true"
      className={'absolute h-0 w-0 overflow-hidden'}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
    >
      <defs>
        <symbol id="icon-paypal" viewBox="0 0 32 32">
          <path d="M29.063 9.644c-1.494 6.631-6.106 10.131-13.375 10.131h-2.419l-1.681 10.675h-2.025l-0.106 0.688c-0.069 0.456 0.281 0.863 0.738 0.863h5.175c0.613 0 1.131-0.444 1.231-1.050l0.050-0.262 0.975-6.181 0.063-0.337c0.094-0.606 0.619-1.050 1.231-1.050h0.769c5.012 0 8.938-2.038 10.088-7.925 0.431-2.238 0.269-4.137-0.712-5.55z" />
          <path d="M25.969 2.413c-1.481-1.688-4.163-2.413-7.587-2.413h-9.944c-0.7 0-1.3 0.506-1.406 1.2l-4.144 26.262c-0.081 0.519 0.319 0.988 0.844 0.988h6.144l1.544-9.781-0.050 0.306c0.106-0.694 0.7-1.2 1.4-1.2h2.919c5.731 0 10.219-2.325 11.531-9.063 0.038-0.2 0.075-0.394 0.1-0.581 0.387-2.487 0-4.188-1.35-5.719z" />
        </symbol>
        <symbol id="icon-plus" viewBox="0 0 32 32">
          <path d="M17.612 1.612h-3.223v12.777h-12.777v3.223h12.777v12.777h3.223v-12.777h12.777v-3.223h-12.777z" />
        </symbol>
        <symbol id="icon-affirm_logo" viewBox="0 0 80 32">
          <path d="M6.572 18.031c-2.165 0-4.675 1.035-6.036 2.108l1.246 2.606c1.092-0.996 2.855-1.84 4.446-1.84 1.514 0 2.338 0.498 2.338 1.514 0 0.69-0.556 1.035-1.59 1.169-3.909 0.498-6.975 1.571-6.975 4.58 0 2.376 1.705 3.832 4.35 3.832 1.897 0 3.583-1.054 4.388-2.434v2.050h3.526v-8.604c0-3.545-2.472-4.982-5.691-4.982zM5.25 29.241c-0.996 0-1.495-0.479-1.495-1.284 0-1.495 1.667-1.993 4.714-2.319 0.019 1.993-1.341 3.602-3.219 3.602zM45.509 20.637v-2.223h-3.526v13.202h3.775v-6.362c0-3.028 1.82-3.909 3.104-3.909 0.498 0 1.169 0.153 1.61 0.479l0.69-3.487c-0.575-0.249-1.188-0.307-1.686-0.307-1.935 0-3.162 0.862-3.966 2.606zM68.675 18.031c-1.993 0-3.487 1.188-4.273 2.319-0.728-1.475-2.242-2.319-4.081-2.319-1.993 0-3.372 1.111-4.024 2.376v-1.993h-3.641v13.202h3.775v-6.802c0-2.434 1.284-3.602 2.472-3.602 1.073 0 2.069 0.69 2.069 2.491v7.914h3.794v-6.802c0-2.472 1.246-3.602 2.491-3.602 0.996 0 2.050 0.728 2.050 2.472v7.933h3.775v-9.121c0-2.97-1.993-4.465-4.407-4.465zM25.6 17.437v0.977h-5.729v-1.341c0-1.744 0.996-2.242 1.859-2.242 0.498 0 1.15 0.115 1.686 0.422l1.169-2.663c-0.69-0.402-1.82-0.766-3.334-0.766-2.414 0-5.154 1.36-5.154 5.634v0.977h-2.184v2.913h2.184v10.271h3.775v-10.29h5.729v10.29h3.775v-10.29h3.43v-2.913h-3.43v-1.341c0-1.744 0.996-2.242 1.859-2.242 0.958 0 1.686 0.422 1.686 0.422l1.169-2.663s-1.188-0.766-3.334-0.766c-2.395-0.019-5.154 1.341-5.154 5.614zM35.047 18.414h3.775v13.202h-3.775z" />
          <path d="M57.044 0c-10.194 0-19.277 7.071-21.844 16.172h3.698c2.146-6.783 9.447-12.723 18.146-12.723 10.577 0 19.698 8.048 19.698 20.58 0 2.817-0.364 5.346-1.054 7.588h3.583l0.038-0.115c0.594-2.319 0.881-4.829 0.881-7.473 0-13.969-10.175-24.029-23.147-24.029z" />
        </symbol>
        <symbol id="icon-alert" viewBox="0 0 32 32">
          <path d="M16 2.899l13.409 26.726h-26.819l13.409-26.726zM16 0c-0.69 0-1.379 0.465-1.903 1.395l-13.659 27.222c-1.046 1.86-0.156 3.383 1.978 3.383h27.166c2.134 0 3.025-1.522 1.978-3.383h0l-13.659-27.222c-0.523-0.93-1.213-1.395-1.903-1.395v0z" />
          <path d="M18 26c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2z" />
          <path d="M16 22c-1.105 0-2-0.895-2-2v-6c0-1.105 0.895-2 2-2s2 0.895 2 2v6c0 1.105-0.895 2-2 2z" />
        </symbol>
        <symbol id="icon-bank" viewBox="0 0 34 32">
          <path d="M32 30v-2h-2v-12h2v-2h-6v2h2v12h-6v-12h2v-2h-6v2h2v12h-6v-12h2v-2h-6v2h2v12h-6v-12h2v-2h-6v2h2v12h-2v2h-2v2h34v-2h-2z" />
          <path d="M16 0h2l16 10v2h-34v-2l16-10z" />
        </symbol>
        <symbol id="icon-coin" viewBox="0 0 32 32">
          <path d="M15 2c-8.284 0-15 6.716-15 15s6.716 15 15 15c8.284 0 15-6.716 15-15s-6.716-15-15-15zM15 29c-6.627 0-12-5.373-12-12s5.373-12 12-12c6.627 0 12 5.373 12 12s-5.373 12-12 12zM16 16v-4h4v-2h-4v-2h-2v2h-4v8h4v4h-4v2h4v2h2v-2h4l-0-8h-4zM14 16h-2v-4h2v4zM18 22h-2v-4h2v4z" />
        </symbol>
        <symbol id="icon-credit-card" viewBox="0 0 32 32">
          <path d="M29 4h-26c-1.65 0-3 1.35-3 3v18c0 1.65 1.35 3 3 3h26c1.65 0 3-1.35 3-3v-18c0-1.65-1.35-3-3-3zM3 6h26c0.542 0 1 0.458 1 1v3h-28v-3c0-0.542 0.458-1 1-1zM29 26h-26c-0.542 0-1-0.458-1-1v-9h28v9c0 0.542-0.458 1-1 1zM4 20h2v4h-2zM8 20h2v4h-2zM12 20h2v4h-2z" />
        </symbol>
      </defs>
    </svg>
  );
}

export default IconsCheckout;
