import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconPlus } from '../icons/Icons';
import IconTrash  from '../../icons/IconTrash'
import { updateCheckoutField } from '../../state/checkout';
import InputField from '../form/InputField';

const CheckoutDetails = ({ setStep }) => {
  const checkout = useSelector(state => state.checkout);
  const dispatch = useDispatch();
  const updateShipping = e => {
    dispatch(updateCheckoutField({ name: e.target.name, value: e.target.value }));
  };
  return (
    <div className="w-full">
      <form>
        <section>
          <div className="flex flex-col justify-center py-10">
            <h1 className="px-10 pb-4 text-3xl font-bold uppercase text-accent">
              SHIPPING DETAILS
            </h1>
            <div className="grid w-full grid-cols-4 ">
              <div className="col-span-4 flex justify-center ">
                <div className="grid w-full grid-cols-4 gap-y-4 gap-x-5 px-10">
                  <InputField
                    className="col-span-4 lg:col-span-2"
                    name={'shipping_name'}
                    label={'First Name'}
                    form={checkout}
                    onChange={updateShipping}
                  />
                  <InputField
                    className="col-span-4 lg:col-span-2"
                    name={'shipping_last_name'}
                    label={'Last Name'}
                    form={checkout}
                    onChange={updateShipping}
                  />
                  <InputField
                    className="col-span-4 lg:col-span-3"
                    name={'shipping_address'}
                    label={'Address'}
                    form={checkout}
                    onChange={updateShipping}
                  />
                  <InputField
                    className="col-span-4 lg:col-span-1"
                    name={'shipping_address2'}
                    label={'Apt/Suite'}
                    form={checkout}
                    required={false}
                    onChange={updateShipping}
                  />
                  <InputField
                    className="col-span-4 lg:col-span-2"
                    name={'shipping_city'}
                    label={'City'}
                    form={checkout}
                    onChange={updateShipping}
                  />
                  <InputField
                    className="col-span-2 lg:col-span-1"
                    name={'shipping_state'}
                    label={'State'}
                    form={checkout}
                    onChange={updateShipping}
                  />{' '}
                  <InputField
                    className="col-span-2 lg:col-span-1"
                    name={'shipping_zip'}
                    label={'Zip'}
                    form={checkout}
                    onChange={updateShipping}
                  />
                  <InputField
                    className="col-span-4 lg:col-span-2"
                    name={'phone'}
                    label={'Telephone'}
                    form={checkout}
                    onChange={updateShipping}
                  />
                  <InputField
                    className="col-span-4 lg:col-span-2"
                    name={'email'}
                    label={'E-Mail'}
                    form={checkout}
                    onChange={updateShipping}
                  />
                  <button
                    type="reset"
                    className="text-md col-span-2 text-left text-gray-400 hover:text-accent lg:col-span-1 xl:col-span-1"
                  >
                    <IconTrash />
                    &ensp; Clear Form
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="w-full px-10">
            <div className="border-t-2" />
          </div>
          <div className="w-full py-10">
            <h1 className="px-10 pb-4 text-3xl font-bold uppercase text-accent ">
              BILLING DETAILS
            </h1>
            <div>
              <button className="group text-md col-span-2 px-10 pb-3 text-left text-gray-400 hover:text-accent lg:col-span-1 xl:col-span-1">
                <IconPlus
                  className={'rotate-45 transform duration-150 ease-in group-hover:rotate-0'}
                />
                &ensp; Copy Shipping Info
              </button>
              <div className="flex justify-center">
                <div className="grid w-full grid-cols-4 ">
                  <div className="col-span-4 flex justify-center ">
                    <div className="grid w-full grid-cols-4 gap-y-4 gap-x-5 px-10">
                      <InputField
                        className="col-span-4 lg:col-span-2"
                        name={'billing_name'}
                        label={'First Name'}
                        form={checkout}
                        onChange={updateShipping}
                      />
                      <InputField
                        className="col-span-4 lg:col-span-2"
                        name={'billing_last_name'}
                        label={'Last Name'}
                        form={checkout}
                        onChange={updateShipping}
                      />
                      <InputField
                        className="col-span-4 lg:col-span-3"
                        name={'billing_address'}
                        label={'Address'}
                        form={checkout}
                        onChange={updateShipping}
                      />
                      <InputField
                        className="col-span-4 lg:col-span-1"
                        name={'billing_address2'}
                        label={'Apt/Suite'}
                        form={checkout}
                        required={false}
                        onChange={updateShipping}
                      />
                      <InputField
                        className="col-span-4 lg:col-span-2"
                        name={'billing_city'}
                        label={'City'}
                        form={checkout}
                        onChange={updateShipping}
                      />
                      <InputField
                        className="col-span-2 lg:col-span-1"
                        name={'billing_state'}
                        label={'State'}
                        form={checkout}
                        onChange={updateShipping}
                      />{' '}
                      <InputField
                        className="col-span-2 lg:col-span-1"
                        name={'billing_zip'}
                        label={'Zip'}
                        form={checkout}
                        onChange={updateShipping}
                      />
                      <InputField
                        className="col-span-4 lg:col-span-2"
                        name={'billing_phone'}
                        label={'Telephone'}
                        form={checkout}
                        onChange={updateShipping}
                      />
                      <InputField
                        className="col-span-4 lg:col-span-2"
                        name={'billing_email'}
                        label={'E-Mail'}
                        form={checkout}
                        onChange={updateShipping}
                      />
                      <button
                        type="reset"
                        className="text-md col-span-2 text-left text-gray-400 hover:text-accent lg:col-span-1 xl:col-span-1"
                      >
                        <IconTrash />
                        &ensp; Clear Form
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="px-10 pb-20 lg:pb-0">
          <button
            className="col-span-2 w-48 rounded-md border-2 border-transparent bg-accent py-2 font-bold text-white hover:border-accent hover:bg-white hover:text-accent lg:col-span-1 xl:col-span-1"
            onClick={e => {
              e.preventDefault();
              setStep(step => step + 1);
            }}
          >
            NEXT
          </button>
        </div>
      </form>
    </div>
  );
};

export default CheckoutDetails;
