import * as React from 'react';

const CheckoutPayment = ({ setStep }) => {
  return (
    <div className="w-full">
      <form>
        <section>
          <div className="flex flex-col py-10 lg:flex-row ">
            <div className="px-10">
              <h1 className="pb-4 text-3xl font-bold uppercase text-accent">SHIPPING DETAILS</h1>
              <div>
                Keith Gray
                <br />
                9595 Harding Ave.
                <br />
                Surfside, FL 33154
              </div>
              <button className="my-5 text-gray-500 hover:text-accent">Change</button>
            </div>
            <div className="m-5 border-t-2" />
            <div className="px-10 lg:border-l-2">
              <h1 className="pb-4 text-3xl font-bold uppercase text-accent">BILLING DETAILS</h1>
              <div>
                Keith Gray
                <br />
                9595 Harding Ave.
                <br />
                Surfside, FL 33154
              </div>
              <button className="my-5 text-gray-500 hover:text-accent">Change</button>
            </div>
          </div>
        </section>

        <section className="flex w-full flex-col justify-center p-10">
          <h1 className="text-3xl font-bold uppercase text-accent">CHOOSE PAYMENT METHOD</h1>

          <div className="flex flex-col">
            <label className="radio-label w-80">
              <input
                type="radio"
                className="radio-input"
                name="payment_method"
                value="Credit Card"
              />
              <div className="radio-design" />
              <div className="radio-text">Credit Card</div>
            </label>

            <label className="radio-label w-80">
              <input type="radio" className="radio-input" name="payment_method" value="PayPal" />
              <div className="radio-design" />
              <div className="radio-text">PayPal</div>
            </label>

            <label className="radio-label w-80">
              <input
                type="radio"
                className="radio-input"
                name="payment_method"
                value="Wire Transfer"
              />
              <div className="radio-design" />
              <div className="radio-text">Wire Transfer</div>
            </label>

            <label className="radio-label w-80">
              <input type="radio" className="radio-input" name="payment_method" value="Affirm" />
              <div className="radio-design" />
              <div className="radio-text">Affirm</div>
            </label>

            <label className="radio-label w-80">
              <input type="radio" className="radio-input" name="payment_method" value="BitPay" />
              <div className="radio-design" />
              <div className="radio-text">BitPay</div>
            </label>
          </div>
        </section>

        <div className="px-10 pb-20 lg:pb-0">
          <button
            className="col-span-2 w-48 rounded-md border-2 border-transparent bg-accent py-2 font-bold text-white hover:border-accent hover:bg-white hover:text-accent lg:col-span-1 xl:col-span-1"
            onClick={e => {
              e.preventDefault();
              setStep(step => step + 1);
            }}
          >
            NEXT
          </button>
        </div>
      </form>
    </div>
  );
};

export default CheckoutPayment;
