import * as React from 'react';
import Seo from '../components/layout/Seo';
import CheckoutMessage from '../components/checkout/CheckoutMessage';
import CheckoutProcess from '../components/checkout/CheckoutProcess';
import Icon from '../components/icons/Icons';
import IconsCheckout from '../components/icons/IconsCheckout';
import CheckOutHeader from '../components/checkout/CheckOutHeader';
import '../styles/main.scss'

const CheckoutPage = () => {
  return (
    <div>
      <CheckOutHeader />
      <IconsCheckout />
      <Icon />
      <Seo title="Checkout | sellusyourjewelry.com" />

      <div className="thankyouHeader w-screen p-20">
          <h1 className="text-center text-6xl font-black text-white ">CHECKOUT</h1>
      </div>

      <div>
        <CheckoutMessage />
        <CheckoutProcess />
      </div>

      <div className={'container mx-auto mt-16 flex flex-wrap gap-y-16 px-4 md:p-0'} />
      <section className={'mt-16'} />
    </div>
  );
};

export default CheckoutPage;
