import * as React from 'react';

const CheckOutHeader = () => {
  return (
    <div className="z-50 flex flex-col justify-center bg-accent py-2 px-20 font-bold text-white md:flex-row md:justify-between">
      <div className="flex w-full items-center justify-center  p-2 md:justify-start">LOGO</div>{' '}
      <div className="flex w-full items-center justify-center  md:justify-end">
        Questions?{' '}
        <a className="  text-xl text-white" href="tel:+13057706955">
          &nbsp;305.770.6955
        </a>
      </div>
    </div>
  );
};

export default CheckOutHeader;
