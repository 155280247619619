import * as React from 'react';
import CheckoutPayment from './CheckoutPayment';
import CheckoutDetails from './CheckoutDetails';
import CheckoutSummary from './CheckoutSummary';
import { useState } from 'react';
import { IconChevronRight } from '../icons/Icons';
import CartSummary from './CartSummary';

const CheckoutProcess = () => {
  const [step, setStep] = useState(1);
  return (
    <section>
      <div className="flex h-10 w-full  items-center justify-center border-b-2 border-accent">
        <div className="flex w-full flex-row items-center justify-start gap-12 px-5 md:w-10/12 md:px-0">
          <div className={' ' + (step === 1 ? ' text-accent' : '')}>DETAILS</div>
          <IconChevronRight />
          <div className={' ' + (step === 2 ? ' text-accent' : '')}>PAYMENT</div>
          <IconChevronRight />
          <div className={' ' + (step === 3 ? ' text-accent' : '')}>SUMMARY</div>
        </div>
      </div>
      <div className="flex justify-center py-10">
        <div className="flex w-full flex-col md:flex-row xl:w-10/12 2xl:w-8/12 ">
          <div
            className={
              'flex w-full flex-col justify-center' +
              (step === 3 ? ' order-last md:order-none' : '')
            }
          >
            {step === 1 ? <CheckoutDetails setStep={setStep} /> : <></>}
            {step === 2 ? <CheckoutPayment setStep={setStep} /> : <></>}
            {step === 3 ? <CheckoutSummary setStep={setStep} /> : <></>}
          </div>
          <CartSummary />
        </div>
      </div>
    </section>
  );
};

export default CheckoutProcess;
